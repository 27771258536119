import React from "react"

const AboutDocument = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path d="M89.81,73H74V32A12,12,0,0,0,62,20H18.5c-.23,0-.46-.06-.7-.08a12.27,12.27,0,0,0-9.13,3.26A11.2,11.2,0,0,0,5.08,31.4a1.5,1.5,0,0,0,1.5,1.5H26V73.71A12.13,12.13,0,0,0,37.19,86h.06l.24,0H79.35a11.75,11.75,0,0,0,12-11.5A1.5,1.5,0,0,0,89.81,73ZM8.22,29.9a8.3,8.3,0,0,1,2.51-4.53A9.17,9.17,0,0,1,16.15,23a1.49,1.49,0,0,0,.35,0h1.9a9.05,9.05,0,0,1,7.33,6.9ZM37.37,83A9.13,9.13,0,0,1,29,73.71V32.19A12.45,12.45,0,0,0,25,23H62a9,9,0,0,1,9,9V73H48.42a1.5,1.5,0,0,0-1.5,1.5,8.23,8.23,0,0,1-2.65,6A9.26,9.26,0,0,1,37.37,83Zm42,0H46l.31-.28A11.24,11.24,0,0,0,49.82,76H88.17A8.86,8.86,0,0,1,79.35,83Z"/>
    <path d="M36.5,42h27a1.5,1.5,0,0,0,0-3h-27a1.5,1.5,0,0,0,0,3Z"/>
    <path d="M36.5,51h27a1.5,1.5,0,0,0,0-3h-27a1.5,1.5,0,0,0,0,3Z"/>
    <path d="M52,58.5A1.5,1.5,0,0,0,50.5,57h-14a1.5,1.5,0,0,0,0,3h14A1.5,1.5,0,0,0,52,58.5Z"/>
  </svg>
)

export default AboutDocument
