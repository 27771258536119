import React from "react"

const AboutRocket = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path d="M83.89,15.11A7.29,7.29,0,0,0,78.21,13,50.71,50.71,0,0,0,46,27.85L44.87,29H30.13a1.5,1.5,0,0,0-1.07.45l-15.63,16A1.5,1.5,0,0,0,14.5,48H28.88l3.85,3.85a22.9,22.9,0,0,0-15.48,6.82C5,71.07,14.65,85.22,14.75,85.36a1.5,1.5,0,0,0,2.73-.86c0-4.81,4.51-9.37,9.27-9.37a8.63,8.63,0,0,1,8.58,8.66A1.5,1.5,0,0,0,37.68,85,24.09,24.09,0,0,0,47,66.11l4,4V84.5a1.5,1.5,0,0,0,2.56,1.06l16-16A1.5,1.5,0,0,0,70,68.52V54.13L71.15,53A50.71,50.71,0,0,0,86,20.79,7.29,7.29,0,0,0,83.89,15.11ZM30.76,32H41.87l-13,13H18.06Zm7.18,48.83a11.61,11.61,0,0,0-11.2-8.69c-4.92,0-9.5,3.46-11.38,8-1.75-4.37-3.16-12.1,4-19.33A19.89,19.89,0,0,1,35.85,55l8,8A21.82,21.82,0,0,1,37.94,80.83ZM67,67.9l-13,13V70.13l13-13Zm2-17-1.56,1.56-.05.05L52.5,67.38,31.62,46.5,46.53,31.59l.05-.05L48.14,30A47.71,47.71,0,0,1,78.42,16,4.22,4.22,0,0,1,83,20.58,47.71,47.71,0,0,1,69,50.86Z"/>
    <path d="M63.5,27a9.5,9.5,0,1,0,6.72,2.78A9.44,9.44,0,0,0,63.5,27Zm4.6,14.1A6.5,6.5,0,1,1,70,36.5,6.46,6.46,0,0,1,68.1,41.1Z"/>
  </svg>

)

export default AboutRocket
